import { ListItemText, MenuItem } from "@mui/material";
import { useCallback } from "react";
import {
  BaseProduct,
  SelectedProduct,
} from "@store/plan-creation/plan-creation-slice-helper-types";
import { Color } from "@styles/default-colors";
import { useAppDispatch } from "@store/store-helper";
import { setSelectedProductIdentifier } from "@store/plan-creation/plan-creation-slice";

type Props = SelectedProduct & Pick<BaseProduct, "name" | "description">;

// TODO: It can later become generic and part of HBSelect
/** Returns a callback that renders a listItem text with a value for product related dropdown */
export function useProductItem(): {
  getMenuItem: ({ type, identifier, name, description }: Props) => JSX.Element;
} {
  const dispatch = useAppDispatch();

  const getMenuItem = useCallback(
    ({ type, identifier, name, description }: Props) => (
      <MenuItem key={identifier} value={identifier}>
        <ListItemText
          primary={name}
          secondary={description}
          onClick={() =>
            dispatch(
              setSelectedProductIdentifier({
                type,
                identifier,
              })
            )
          }
          sx={{
            margin: "0px",
            "& .MuiListItemText-secondary": {
              color: Color.grey39,
            },
          }}
        />
      </MenuItem>
    ),
    [dispatch]
  );

  return { getMenuItem };
}
