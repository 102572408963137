import { useEffect, useState } from "react";
import { PlanCreationSummary } from "@pages/create-plan/create-plan-container/plan-creation-summary";
import { Constraint } from "@utils/constraint";
import { LoadingScreen } from "@components/loading-screen";
import { HBSpinner } from "@components/hb-customs/hb-spinner";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  activeConstraintsSelector,
  isFetchingProductsSelector,
} from "@store/plan-creation/plan-creation-selector";
import {
  fetchNonSingleFeatures,
  fetchSingleFeatures,
} from "@store/plan-creation/plan-creation-slice-thunks";
import {
  setActiveConstraints,
  setCustomProducts,
  setTrialProducts,
} from "@store/plan-creation/plan-creation-slice";
import { CreatePlanForm } from "@pages/create-plan/create-plan-container/create-plan-form";
import { isFetchingPlanCreationDataSelector } from "@store/plan-creation/plan-creation-selector";

/** Container that holds the plan creation page and its summary */
export function CreatePlanContainer(): JSX.Element {
  const isFetchingProducts = useAppSelector(isFetchingProductsSelector);
  const dispatch = useAppDispatch();
  const isFetchingData = useAppSelector(isFetchingPlanCreationDataSelector);
  const activeConstraints = useAppSelector(activeConstraintsSelector);

  /** Fetch the products */
  useEffect(() => {
    async function fetchProducts(): Promise<void> {
      await dispatch(fetchSingleFeatures());
      await dispatch(fetchNonSingleFeatures());
      dispatch(setCustomProducts());
      dispatch(setTrialProducts());
    }

    fetchProducts();
  }, [dispatch]);

  const [shouldShowSummary, setShouldShowSummary] = useState<boolean>(false);

  // Filter out constraints with zero or no value
  useEffect(() => {
    if (shouldShowSummary) {
      const updatedConstraints = activeConstraints.filter((constraint) => {
        return Constraint.getMaxValueOfConstraint(constraint) > 0;
      });
      dispatch(setActiveConstraints(updatedConstraints));
    }
    // TODO: Fix the infinite loop: https://faro01.atlassian.net/browse/ST-2358
    // eslint-disable-next-line react-hooks/exhaustive-deps -- adding activeConstraints will cause infinite loop
  }, [dispatch, shouldShowSummary]);

  if (isFetchingData || isFetchingProducts) {
    return <HBSpinner />;
  }

  return (
    <LoadingScreen isFetching={isFetchingData}>
      {/* TODO: Block navigating away if there is an unsaved form: https://faro01.atlassian.net/browse/ST-2322 */}

      <Box sx={{ margin: "10px 110px 0px" }}>
        {shouldShowSummary ? (
          <PlanCreationSummary setShouldShowSummary={setShouldShowSummary} />
        ) : (
          <CreatePlanForm setShouldShowSummary={setShouldShowSummary} />
        )}
      </Box>
    </LoadingScreen>
  );
}
