import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminAPITypes } from "@stellar/api-logic";
import {
  BaseProduct,
  ProductList,
  SelectedProduct,
} from "@store/plan-creation/plan-creation-slice-helper-types";
import {
  fetchNonSingleFeatures,
  fetchSingleFeatures,
} from "@store/plan-creation/plan-creation-slice-thunks";
import {
  allAvailableCustomProducts,
  allAvailableTrialProducts,
  allProducts,
} from "@utils/product/product-list";
import {
  defaultPlanActiveConstraints,
  defaultPlanDuration,
} from "@store/plan-creation/plan-creation-slice-helper";
import {
  isProductAllowedAddon,
  isProductAllowedBundle,
  isProductAllowedFeature,
  isProductAllowedSubscription,
} from "@utils/type-guards";
import { AllowedFeatureTypes } from "@utils/product/product-types";
import { PartialNull } from "@utils/utility-types";

export interface PlanCreationState {
  /** The type of plan subject */
  planSubject: {
    /** The ID of the plan subject, e.g. company, project or account */
    planSubjectId: string | null;

    /** The type of the plan subject, e.g. company, project or account */
    planSubjectType: AdminAPITypes.ESubjectType | null;

    /** The name of the plan subject, e.g. company, project or account */
    planSubjectName: string | null;
  };

  /** List of all the products allowed to be selected */
  products: PartialNull<ProductList>;

  /** The identifier of the selected product, either a feature, bundle or a subscription */
  selectedProductIdentifier: SelectedProduct | null;

  /** Active constraints added to the plan */
  activeConstraints: AdminAPITypes.CreateConstraintPayload[];

  /**
   * Start and end date of the plan creation
   * Setting initial startDate & endDate to "now" because the time of the day does not matter at this point and will
   * be adjusted before creating the plan through API. Using start/end of day at this point can cause problems because
   * of different time zones vs. UTC and adds to much confusion in general.
   */
  creationDates: {
    startDate: number;
    endDate: number;
  };

  /** Comments added to the plan */
  comments: string;

  /** Whether the data is being fetched */
  isFetching: boolean;
}

const initialState: PlanCreationState = {
  planSubject: {
    planSubjectId: null,
    planSubjectType: null,
    planSubjectName: null,
  },
  products: {
    feature: null,
    bundle: null,
    subscription: null,
    addon: null,
    custom: null,
    trial: null,
  },
  selectedProductIdentifier: null,
  activeConstraints: [],
  comments: "",
  creationDates: {
    startDate: new Date().getTime(),
    endDate: defaultPlanDuration(null, new Date().getTime()),
  },
  isFetching: false,
};

/** Slice to access state of plan creation */
const planCreationSlice = createSlice({
  name: "planCreation",
  initialState,
  reducers: {
    setPlanSubject(
      state,
      action: PayloadAction<PlanCreationState["planSubject"]>
    ) {
      state.planSubject = action.payload;
    },

    /** Set the selected product identifier */
    setSelectedProductIdentifier(
      state,
      action: PayloadAction<SelectedProduct | null>
    ) {
      state.selectedProductIdentifier = action.payload;

      if (action.payload) {
        // Adjust the default plan duration if the selected product is changed
        state.creationDates = {
          ...initialState.creationDates,
          endDate: defaultPlanDuration(
            action.payload.identifier ?? null,
            state.creationDates.startDate
          ),
        };
        state.activeConstraints = defaultPlanActiveConstraints(
          action.payload.identifier ?? null
        );
      }

      // Adjust the default plan active constraints if the selected product is changed
    },

    /** Set the custom products to the product store */
    setCustomProducts(state) {
      state.products = {
        ...state.products,
        custom: allAvailableCustomProducts,
      };
    },

    /** Set the trial products to the product store */
    setTrialProducts(state) {
      state.products = {
        ...state.products,
        trial: allAvailableTrialProducts,
      };
    },

    setActiveConstraints(
      state,
      action: PayloadAction<AdminAPITypes.CreateConstraintPayload[]>
    ) {
      state.activeConstraints = action.payload;
    },

    setCreationDates(
      state,
      action: PayloadAction<Partial<PlanCreationState["creationDates"]>>
    ) {
      state.creationDates = {
        startDate: action.payload.startDate ?? state.creationDates.startDate,
        endDate: action.payload.endDate ?? state.creationDates.endDate,
      };
    },

    setComments(state, action: PayloadAction<string>) {
      state.comments = action.payload;
    },

    setIsFetchingData(state, action: PayloadAction<boolean>) {
      state.isFetching = action.payload;
    },

    /** Reset the whole plan creation slice */
    resetPlanCreationState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSingleFeatures.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(fetchSingleFeatures.fulfilled, (state, action) => {
        state.isFetching = false;

        const features = action.payload.allSingleFeatures.reduce(
          (acc, feature) => {
            if (isProductAllowedFeature(feature.identifier)) {
              const product = allProducts.feature?.[feature.identifier];
              if (product) {
                acc[feature.identifier] = {
                  ...product,
                  // The name and description of our definition has priority over what we receive from backend
                  name: product.name ?? feature.name,
                  description: product.description ?? feature.description,
                };
              }
            }
            return acc;
          },
          {} as Record<AllowedFeatureTypes, BaseProduct>
        );

        state.products.feature = features;
      })
      .addCase(fetchSingleFeatures.rejected, (state, action) => {
        state.isFetching = false;
      })

      .addCase(fetchNonSingleFeatures.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(fetchNonSingleFeatures.fulfilled, (state, action) => {
        state.isFetching = false;

        const nonSingleFeatures = action.payload.allNonSingleFeatures.reduce(
          (acc, feature) => {
            if (isProductAllowedBundle(feature.identifier)) {
              const product = allProducts.bundle?.[feature.identifier];
              if (product) {
                acc.bundle[feature.identifier] = {
                  ...product,
                  // The name and description of our definition has priority over what we receive from backend
                  name: product.name ?? feature.name,
                  description: product.description ?? feature.description,
                };
              }
            }
            if (isProductAllowedSubscription(feature.identifier)) {
              const product = allProducts.subscription?.[feature.identifier];
              if (product) {
                acc.subscription[feature.identifier] = {
                  ...product,
                  // The name and description of our definition has priority over what we receive from backend
                  name: product.name ?? feature.name,
                  description: product.description ?? feature.description,
                };
              }
            }
            if (isProductAllowedAddon(feature.identifier)) {
              const product = allProducts.addon?.[feature.identifier];
              if (product) {
                acc.addon[feature.identifier] = {
                  ...product,
                  // The name and description of our definition has priority over what we receive from backend
                  name: product.name ?? feature.name,
                  description: product.description ?? feature.description,
                };
              }
            }
            return acc;
          },
          {
            bundle: {},
            subscription: {},
            addon: {},
          } as {
            bundle: ProductList["bundle"];
            subscription: ProductList["subscription"];
            addon: ProductList["addon"];
          }
        );

        state.products = {
          ...state.products,
          ...nonSingleFeatures,
        };
      })
      .addCase(fetchNonSingleFeatures.rejected, (state, action) => {
        state.isFetching = false;
      });
  },
});

export const {
  setPlanSubject,
  setSelectedProductIdentifier,
  setCustomProducts,
  setTrialProducts,
  setActiveConstraints,
  setCreationDates,
  setComments,
  setIsFetchingData,
  resetPlanCreationState,
} = planCreationSlice.actions;

export const planCreationReducer = planCreationSlice.reducer;
