import { CoreApi } from "@api/index";
import { useErrorHandler } from "@components/error-boundary/error-handling-context";
import { useAppNavigation } from "@router/use-app-navigation";
import { AdminAPITypes } from "@stellar/api-logic";
import { activeConstraintsSelector } from "@store/plan-creation/plan-creation-selector";
import { useAppSelector } from "@store/store-helper";
import { useCallback } from "react";
import { planSubjectSelector } from "@store/plan-creation/plan-creation-selector";
import { assert } from "@utils/assert";

interface ReturnProps {
  /** Create a device plan */
  createDevicePlan: (
    plan: AdminAPITypes.CreatePlanPayload,
    planSubjectId: string
  ) => Promise<void>;

  /** Create a plan */
  createPlan: (plan: AdminAPITypes.CreatePlanPayload) => Promise<void>;
}

/** Hook to create a plan */
export function useCreatePlan(): ReturnProps {
  const { navigateToProfilePage } = useAppNavigation();
  const { handleErrorWithDialog } = useErrorHandler();
  const { planSubjectId, planSubjectType } =
    useAppSelector(planSubjectSelector);
  assert(planSubjectType, "Plan subject type should be available");
  assert(planSubjectId, "Plan subject ID should be available");
  const activeConstraints = useAppSelector(activeConstraintsSelector);

  const createDevicePlan = useCallback(
    async (plan: AdminAPITypes.CreatePlanPayload, planSubjectId: string) => {
      // For Device subject type, subscriber info is required as the subjectId alone can't identify the subscriber.
      const devicePlan: AdminAPITypes.CreateDevicePlanPayload = {
        ...plan,
        subscriber: {
          // We used companyId as the planSubjectId for the Hardware Licenses.
          // So, in this context, planSubjectId serves as companyId, representing the devicePlan subscriber.
          id: planSubjectId,
          type: AdminAPITypes.ESubscriberType.company,
        },
      };
      try {
        await CoreApi.V0.ADMIN.createPlan(devicePlan);
        navigateToProfilePage(
          AdminAPITypes.ESubjectType.company,
          planSubjectId
        );
      } catch (error) {
        handleErrorWithDialog("Error while creating device plan", error);
      }
    },
    [handleErrorWithDialog, navigateToProfilePage]
  );

  const createPlan = useCallback(
    async (plan: AdminAPITypes.CreatePlanPayload) => {
      try {
        await CoreApi.V0.ADMIN.createPlan(plan);
        navigateToProfilePage(planSubjectType, planSubjectId);
      } catch (error) {
        const typedError = error as { devMessage?: string };

        // Determine if the error message indicates a conflict with an existing user role constraint
        const hasUserRoleConstraint = activeConstraints.find(
          (constraint) =>
            constraint.type === AdminAPITypes.EConstraintType.userRole
        );
        if (
          hasUserRoleConstraint &&
          typedError?.devMessage?.includes("conflicts")
        ) {
          handleErrorWithDialog(
            "A user role limit is already set for this company. " +
              "Please deactivate the existing limit before adding a new one.",
            error
          );
        } else {
          handleErrorWithDialog(
            "An error occurred while creating a new plan. Please try again later.",
            error
          );
        }
      }
    },
    [
      activeConstraints,
      handleErrorWithDialog,
      navigateToProfilePage,
      planSubjectId,
      planSubjectType,
    ]
  );

  return { createDevicePlan, createPlan };
}
