import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { ProductIdentifier } from "@store/plan-creation/plan-creation-slice-helper-types";
import {
  AllowedCustomProductTypes,
  AllowedTrialProductTypes,
} from "@utils/product/product-types";

/** Maps the custom product ID to the product identifications that will be created for */
export const customProductIdToBundleIdentification: Record<
  AllowedCustomProductTypes,
  ProductIdentifier[]
> = {
  standard360: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGStandard,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
  ],

  standardPointCloud: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGStandard,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
  ],

  pro360: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGPro,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
  ],

  proPointCloud: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGPro,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
  ],

  enterprise360: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
  ],

  enterprisePointCloud: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
  ],

  enterprise360PointCloud: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
  ],
};

/** Maps the trial product ID to the product identifications that will be created for */
export const trialProductIdToBundleIdentification: Record<
  AllowedTrialProductTypes,
  ProductIdentifier[]
> = {
  enterprise360Trial: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXgTrialIndicator,
  ],

  enterprisePointCloudTrial: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXgTrialIndicator,
  ],

  enterprise360PointCloudTrial: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXgTrialIndicator,
  ],
};
