import { createSelector } from "@reduxjs/toolkit";
import { AdminAPITypes } from "@stellar/api-logic";
import { RootState } from "@store/store-helper";
import {
  BaseProduct,
  ProductType,
  SelectedProduct,
} from "@store/plan-creation/plan-creation-slice-helper-types";
import { PlanCreationState } from "@store/plan-creation/plan-creation-slice";

export const planSubjectSelector: (
  state: RootState
) => PlanCreationState["planSubject"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.planCreation.planSubject;
  }
);

/** Returns all the stored products */
export const allProductsSelector: (
  state: RootState
) => PlanCreationState["products"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.planCreation.products;
  }
);

/** Returns the selected product identifier */
export const selectedProductIdentifierSelector: (
  state: RootState
) => PlanCreationState["selectedProductIdentifier"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.planCreation.selectedProductIdentifier;
  }
);

/** Gets a product by providing the identifier */
export function getProductByIdentifierSelector<T extends ProductType>({
  type,
  identifier,
}: SelectedProduct<T>): (state: RootState) => BaseProduct | null {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (!type || !identifier) {
        return null;
      }
      const allProductsOfType = allProductsSelector(state)[type];
      if (!allProductsOfType) {
        return null;
      }

      return allProductsOfType[identifier] ?? null;
    }
  );
}

/** Returns the selected product */
export const selectedProductSelector: (state: RootState) => BaseProduct | null =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedProductIdentifier =
        selectedProductIdentifierSelector(state);

      return getProductByIdentifierSelector({
        type: selectedProductIdentifier?.type,
        identifier: selectedProductIdentifier?.identifier,
      })(state);
    }
  );

/** Get all the products of provided type from the store for the provided planSubjectType */
export function allProductsOfTypeSelector<T extends ProductType>({
  productType,
  planSubjectType,
}: {
  productType: T;
  planSubjectType: AdminAPITypes.ESubjectType;
}): (state: RootState) => BaseProduct<T>[] {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const allProductsOfType = allProductsSelector(state)[productType];

      if (!allProductsOfType) {
        return [];
      }

      /** All the products of type productType that have planSubjectType as their scope */
      return Object.values(allProductsOfType).filter((product) => {
        return planSubjectType in (product as BaseProduct<T>).scope;
      }) as BaseProduct<T>[];
    }
  );
}

/** Whether the products are fetching or not */
export const isFetchingProductsSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.planCreation.isFetching;
    }
  );

/** Returns true if the selected product should have limits */
export function shouldSelectedProductHaveLimits(
  planSubjectType: AdminAPITypes.ESubjectType
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedProduct = selectedProductSelector(state);
      if (selectedProduct === null) {
        return false;
      }
      return selectedProduct.scope[planSubjectType] ?? false;
    }
  );
}

export const activeConstraintsSelector: (
  state: RootState
) => AdminAPITypes.CreateConstraintPayload[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.planCreation.activeConstraints;
  }
);

export const creationDatesSelector: (
  state: RootState
) => PlanCreationState["creationDates"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.planCreation.creationDates;
  }
);

export const commentsSelector: (state: RootState) => string = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.planCreation.comments;
  }
);

/** Whether the data is being fetched or not */
export const isFetchingPlanCreationDataSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.planCreation.isFetching;
    }
  );
